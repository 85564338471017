<template>
  <div>
    <v-btn class="ma-2" elevation="2" to="/">Home</v-btn>
    <v-btn class="ma-2" elevation="2" to="/worldwide"
      >World Wide (excluding Myanmar)</v-btn
    >
    <v-btn class="ma-2" elevation="2" to="/postList">Post List</v-btn>
    <v-btn class="ma-2" elevation="2" to="/lessonList">Lesson List</v-btn>
    <v-btn class="ma-2" elevation="2" to="/questionList">Question List</v-btn>
    <v-btn class="ma-2" elevation="2" to="/userList">User List</v-btn>
    <v-btn class="ma-2" elevation="2" to="/trainees">Trainees</v-btn>
    <v-btn class="ma-2" elevation="2" to="/training">Training Dashboard</v-btn>
    <v-btn class="ma-2" elevation="2" to="/publicdashboard"
      >Public Dashboard</v-btn
    >
    <v-btn class="ma-2" elevation="2" to="serviceDirectory"
      >Service Directory</v-btn
    >
    <v-btn class="ma-2" elevation="2" to="notifications">Notifications</v-btn>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {};
  },
};
</script>
